<template>
<div id="StatisticsIndex">
</div>
</template>

<script>
export default {
    name: 'StatisticsIndex',
    data() {
        return {}
    }
}
</script>
