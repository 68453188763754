<template>
<div id="DepartmentLists">
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>访客管理</el-breadcrumb-item>
        <el-breadcrumb-item>部门管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :model="topbar" :inline="true" style="margin-top: 20px;">
        <!-- <el-form-item>
            <el-input style="width: 452px" v-model="topbar.keywords" placeholder="部门编码/部门名称" clearable></el-input>
        </el-form-item> -->
        <el-form-item>
            <!-- <el-button @click="doSearch" type="primary"><i class="iconfont icon-search" />&nbsp;搜索</el-button> -->
            <el-button @click="doAdd" type="primary"><i class="iconfont icon-add" />&nbsp;新增</el-button>
        </el-form-item>
    </el-form>
    <el-table v-loading="tableDataLoading" element-loading-text="数据加载中，请稍后......" size="small" :data="tableData" border stripe :height="workerHeight" style="width: 100%;">
        <el-table-column prop="orgNo" label="部门编号" align="center" />
        <el-table-column prop="parentOrgName" label="隶属部门" align="center" />
        <el-table-column prop="orgName" label="部门名称" align="center" />
        <!-- <el-table-column label="操作" align="center" width="200">
            <template #default="scope">
                <el-button @click="viewDetail(scope.row.id)" link type="primary" size="small"><i class="iconfont icon-order" />详情</el-button>
            </template>
        </el-table-column> -->
    </el-table>
</div>
</template>

<script>
export default {
    name: 'DepartmentLists',
    props: ['viewId', 'unionId', 'terminalToken'],
    data() {
        return {
            workerHeight: document.documentElement.clientHeight - 350,
            topbar: {
                keywords: '',
            },
            tableDataLoading: true,
            total: 0,
            pageSize: 10,
            tableData: [],
            visitorOrderDetail: {}
        }
    },
    created() {
        if (this.$options.name == this.viewId) {
            this.getDataLists()
        }
    },
    methods: {
        doSearch() {

        },
        getDataLists() {
            let data = {}
            this.tableDataLoading = true
            let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
            this.$http.post('/visitor/organization/lists', data, {
                'terminal-token': this.terminalToken,
                'terminal-signature': terminalSignature
            }, (response) => {
                this.tableDataLoading = false
                let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                if (signatureVerify) {
                    this.tableData = response.data.data
                } else {
                    this.$message.error('验证签名失败')
                }
            })
        },
        doAdd() {
            this.$emit('menuItemIndex', 'DepartmentAdd')
        }
    }
}
</script>
