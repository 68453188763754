import SM2 from '@/assets/js/utils/sm2'
const stringify = require('json-stable-stringify')
const Signature = {
    signature(data, privateKey) {
        let signatureSource = stringify(data)
        return SM2.signature(signatureSource, privateKey)
    },
    verify(data, publicKey) {
        let signature = data.signature
        //Reflect.deleteProperty(data, 'signature')
        data.data = (data.data) ? data.data : null
        data.signature = null
        let message = stringify(data)
        return SM2.verifySignature(message, signature, publicKey)
    }
}

export default Signature