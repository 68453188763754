const verify = {
    token() {
        let whiteList = ['/', '/console/panel']
        for (var i = 0; i < whiteList.length; i++) {
            if (window.location.pathname == whiteList[i]) {
                return true
            }
        }
        return false
    },
    mobile: (mobile) => {
        let mobileRule = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/
        return (mobileRule.test(mobile))
    }
}

export default verify