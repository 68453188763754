<template>
<div id="DepartmentAdd">
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>访客管理</el-breadcrumb-item>
        <el-breadcrumb-item>部门新增</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :model="form" style="margin: 20px auto 0 auto; padding: 20px; width: 50%">
        <el-form-item label="隶属部门" label-width="100px">
            <el-input v-model="organizationRootName" maxlength="20" placeholder="" disabled />
        </el-form-item>
        <el-form-item label="部门编号" label-width="100px">
            <el-input v-model="organizationCode" maxlength="20" placeholder="" />
        </el-form-item>
        <el-form-item label="部门名称" label-width="100px">
            <el-input v-model="organizationName" maxlength="20" placeholder="" />
        </el-form-item>
        <el-form-item class="pbr">
            <el-button type="primary" @click="doSave">保存</el-button>
        </el-form-item>
    </el-form>
</div>
</template>

<script>
export default {
    name: 'DepartmentAdd',
    props: ['viewId', 'unionId', 'terminalToken'],
    data() {
        return {
            workerHeight: document.documentElement.clientHeight - 350,
            organizationRootCode: '',
            organizationRootName: '',
            organizationCode: '',
            organizationName: '',
        }
    },
    created() {
        if (this.$options.name == this.viewId) {
            this.getOrganizationRoot()
        }
    },
    methods: {
        getOrganizationRoot() {
            let data = {}
            let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
            this.$http.post('/visitor/organization/root', data, {
                'terminal-token': this.terminalToken,
                'terminal-signature': terminalSignature
            }, (response) => {
                let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                if (signatureVerify) {
                    let responseData = response.data.data
                    this.organizationRootCode = responseData.orgIndexCode
                    this.organizationRootName = responseData.orgName
                } else {
                    this.$message.error('验证签名失败')
                }
            })
        },
        doSave() {
            if (this.organizationRootCode.length == 0) {
                this.$message.error('请等待数据初始化完成')
            } else if (this.organizationCode.length == 0) {
                this.$message.error('请填写部门编码')
            } else if (this.organizationName.length == 0) {
                this.$message.error('请填写部门名称')
            } else {
                let data = {
                    parentIndexCode: this.organizationRootCode,
                    organizationCode: this.organizationCode,
                    organizationName: this.organizationName
                }
                let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
                this.$http.post('/visitor/organization/add', data, {
                    'terminal-token': this.terminalToken,
                    'terminal-signature': terminalSignature
                }, (response) => {
                    let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                    if (signatureVerify) {
                        this.$message.success('添加成功')
                    } else {
                        this.$message.error('验证签名失败')
                    }
                })
            }
        }
    }
}
</script>

<style scoped>
.pbr {
    position: fixed;
    bottom: 50px;
    right: 50px;
}
</style>
