import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'PassportSignin',
    component: () => import('@/views/passport/Signin'),
    meta: { title: '通行证登录' }
  },
  {
    path: '/console/panel',
    name: 'ConsolePanel',
    component: () => import('@/views/console/Panel'),
    meta: { title: '控制台面板' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
