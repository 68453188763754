import { createStore } from 'vuex'

export default createStore({
  state: {
    // 客户端私钥：
    // 1. 用于客户端对发送给服务端的数据签名
    // 2. 用于客户端本地数据解密
    clientPrivateKey: 'accdac3bf01f963cf120777905ceaf35d0b0d853636fe1d38a36635c0fda5d84',
    // 客户端公钥：用于客户端本地数据加密
    clientPublicKey: '04096888932d554466382cb7aa9fc3dfacf3650b10452c03c31a9e961ceb04e057523b4f03f27abf62decec4b7fb089f412dddc51d61c9e66742cc5f12f5a18a16',
    // 服务端公钥：
    // 1. 用于客户端对接收到服务端的数据验签
    // 2. 用于客户端对发送给服务端的数据加密
    serverPublicKey: '04ec90e6e0b2d7e62b05c27d23b2a08a5af753b88b3bfd7c3b2f8d2d9757a02655bdc5a6f1e6f2ddde4c430f8b9080c216c6896b32bb2ed4287ac8bb1c54d1c63a',
    terminalToken: null,
  },
  mutations: {
    updateTerminalToken(state, token) {
      localStorage.setItem('terminalToken', token)
      state.terminalToken = token
    }
  },
  actions: {
  },
  modules: {
  }
})
