const db = {
    getRowId: (viewId, unionId) => {
        let rowId = sessionStorage.getItem(viewId + '_unionId')
        if (!rowId) {
            sessionStorage.setItem(viewId + '_unionId', unionId)
            rowId = sessionStorage.getItem(viewId + '_unionId')
        }
        if (unionId && rowId != unionId) {
            rowId = unionId
            sessionStorage.setItem(viewId + '_unionId', unionId)
        }
        return parseInt(rowId)
    },
    getExcel: (excelFields, exportData, fileName, charset) => {
        var arrData = typeof exportData != 'object' ? JSON.parse(exportData) : exportData;
        var excel = '<table>';
        //设置表头
        var row = "<tr>";
        for (var key in excelFields) {
            if (excelFields[key] instanceof Object) {
                row += "<td>" + excelFields[key].field + '</td>';
            } else {
                row += "<td>" + excelFields[key] + '</td>';
            }
            
        }
        //换行
        excel += row + "</tr>";
        // //设置数据
        for (var i = 0; i < arrData.length; i++) {
            row = "<tr>";
            for (var index in arrData[i]) {
                var value = arrData[i][index] === "." ? "" : arrData[i][index];
                value = (excelFields[index] instanceof Object ) ? excelFields[index].callback(value) : value
                row += '<td>' + value + '</td>';
            }
            excel += row + "</tr>";
        }
        excel += "</table>";
        var excelFile = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:x='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'>";
        excelFile += '<meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">';
        excelFile += '<meta http-equiv="content-type" content="application/vnd.ms-excel';
        excelFile += '; charset=UTF-8">';
        excelFile += "<head>";
        excelFile += "<!--[if gte mso 9]>";
        excelFile += "<xml>";
        excelFile += "<x:ExcelWorkbook>";
        excelFile += "<x:ExcelWorksheets>";
        excelFile += "<x:ExcelWorksheet>";
        excelFile += "<x:Name>";
        excelFile += "{worksheet}";
        excelFile += "</x:Name>";
        excelFile += "<x:WorksheetOptions>";
        excelFile += "<x:DisplayGridlines/>";
        excelFile += "</x:WorksheetOptions>";
        excelFile += "</x:ExcelWorksheet>";
        excelFile += "</x:ExcelWorksheets>";
        excelFile += "</x:ExcelWorkbook>";
        excelFile += "</xml>";
        excelFile += "<![endif]-->";
        excelFile += "</head>";
        excelFile += "<body>";
        excelFile += excel;
        excelFile += "</body>";
        excelFile += "</html>";
        charset = (charset) ? charset : 'utf-8'
        var uri = 'data:application/vnd.ms-excel;charset=' + charset + ',' + encodeURIComponent(excelFile);
        var link = document.createElement("a");
        link.href = uri;
        link.style = "visibility:hidden";
        link.download = fileName + ".xls";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }
}

export default db