const sm2 = require('sm-crypto').sm2
const cipherMode = 1
const SM2 = {
    generateKeyPairHex() {
        return sm2.generateKeyPairHex()
    },
    encrypted(plainText, appPublicKey) {
        return sm2.doEncrypt(plainText, appPublicKey, cipherMode)
    },
    decrypted(encryptedText, appPrivateKey) {
        return sm2.doDecrypt(encryptedText, appPrivateKey, cipherMode)
    },
    signature(message, appPrivateKey) {
        return sm2.doSignature(message, appPrivateKey)
    },
    verifySignature(message, signature, appPublicKey) {
        return sm2.doVerifySignature(message, signature, appPublicKey)
    }
}

export default SM2