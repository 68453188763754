<template>
<div id="NavigationMenu">
    <el-menu :default-active="viewId" :style="'overflow-y: auto; overflow-x: hidden; height: ' + workerHeight + 'px'" @select="onSelected" unique-opened>
        <template v-for="(menuItem, menuIndex) in navigationMenus">
            <el-menu-item :index="menuItem.uid" :key="menuIndex" v-if="!menuItem.subMenu">
                <i :class="menuItem.icon"></i>
                <template #title>{{ menuItem.title }}</template>
            </el-menu-item>
            <el-sub-menu :index="menuItem.uid" :key="menuIndex" v-if="menuItem.subMenu">
                <template #title>
                    <i :class="menuItem.icon"></i>
                    <span>{{ menuItem.title }}</span>
                </template>
                <div v-for="(subMenuItem, subMenuIndex) in menuItem.subMenu" :key="subMenuIndex">
                    <el-menu-item v-if="subMenuItem.show" :index="subMenuItem.index">
                        <i :class="subMenuItem.icon"></i>
                        <span>{{subMenuItem.title}}</span>
                    </el-menu-item>
                </div>
            </el-sub-menu>
        </template>
    </el-menu>
</div>
</template>

<script>
export default {
    name: 'NavigationMenu',
    props: ['viewId', 'navigationMenus'],
    data() {
        return {
            workerHeight: document.documentElement.clientHeight - 70
        }
    },
    created() {

    },
    methods: {
        getWorkerArea() {
            this.workerHeight = document.documentElement.clientHeight - 70
        },
        onSelected(menuItemIndex) {
            this.$emit('menuItemIndex', menuItemIndex)
        },
    },
    mounted() {
        window.addEventListener('resize', this.getWorkerArea)
        this.getWorkerArea()
    },
}
</script>
