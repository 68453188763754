<template>
<div id="UserAdd">
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>用户管理</el-breadcrumb-item>
        <el-breadcrumb-item>新增用户</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :model="form" style="margin: 20px auto 0 auto; padding: 20px; width: 50%">
        <el-form-item label="用户类型" label-width="100px">
            <el-radio-group v-model="defaultPassportType" @change="getPassportType">
                <el-radio-button v-for="(item, index) in passportTypeLists" v-bind:key="index" :label="item.label" />
            </el-radio-group>
        </el-form-item>
        <el-form-item label="真实姓名" label-width="100px">
            <el-input v-model="form.realName" maxlength="20" placeholder="请填写真实姓名" />
        </el-form-item>
        <el-form-item label="手机号码" label-width="100px">
            <el-input v-model="form.bindMobile" maxlength="11" placeholder="请填写常用的手机号码" />
        </el-form-item>
        <el-form-item label="邮箱地址" label-width="100px">
            <el-input v-model="form.bindEmail" maxlength="50" placeholder="请填写常用的邮箱地址" />
        </el-form-item>
        <el-form-item label="登录密码" label-width="100px">
            <el-input v-model="form.password" show-password maxlength="20" placeholder="请填写登录密码" />
        </el-form-item>
        <el-form-item label="确认密码" label-width="100px">
            <el-input v-model="form.confirmPassword" show-password maxlength="20" placeholder="请与登录密码保持一致" />
        </el-form-item>
        <el-form-item class="pbr">
            <el-button :loading="submitLoading" type="primary" @click="doSave">{{submitLoading ? '正在注册账户，请稍后......' : '保存'}}</el-button>
        </el-form-item>
    </el-form>
</div>
</template>

<script>
export default {
    name: 'UserAdd',
    props: ['viewId', 'terminalToken'],
    data() {
        return {
            passportTypeLists: [{
                    label: '普通用户',
                    value: 1
                },
                {
                    label: '秩序管理',
                    value: 2
                },
                {
                    label: '系统管理',
                    value: 3
                },
                {
                    label: '内部管理',
                    value: 4
                },
            ],
            submitLoading: false,
            defaultPassportType: '',
            form: {
                passportType: '',
                realName: '',
                bindMobile: '',
                bindEmail: '',
                password: '',
                confirmPassword: '',
            },
        }
    },
    methods: {
        getPassportType(value) {
            this.passportTypeLists.forEach((item) => {
                if (item.label == value) {
                    this.form.passportType = item.value
                }
            })
        },
        doSave() {
            if (this.form.passportType.length == 0) {
                this.$message.error('请选择用户类型')
            } else if (this.form.bindMobile.length == 0) {
                this.$message.error('请填写绑定手机号码')
            } else if (this.form.password.length == 0) {
                this.$message.error('请填写登录密码')
            } else if (this.form.password != this.form.confirmPassword) {
                this.$message.error('两次输入的密码不一致')
            } else {
                this.submitLoading = true
                let data = {
                    signupType: this.form.passportType,
                    mobile: this.$sm2.encrypted(this.form.bindMobile, this.$store.state.serverPublicKey),
                    password: this.$sm2.encrypted(this.form.password, this.$store.state.serverPublicKey)
                }
                if (this.form.realName.length > 0) {
                    data.realName = this.$sm2.encrypted(this.form.realName, this.$store.state.serverPublicKey)
                }
                if (this.form.bindEmail.length > 0) {
                    data.bindEmail = this.$sm2.encrypted(this.form.bindEmail, this.$store.state.serverPublicKey)
                }
                this.tableDataLoading = true
                let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
                this.$http.post('/passport/signup', data, {
                    'terminal-token': this.terminalToken,
                    'terminal-signature': terminalSignature
                }, (response) => {
                    let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                    if (signatureVerify) {
                        this.$message.success('注册成功')
                    } else {
                        this.$message.error('验证签名失败')
                    }
                    this.submitLoading = false
                }, () => {
                    this.submitLoading = false
                })
            }
        }
    }
}
</script>

<style scoped>
.pbr {
    position: fixed;
    bottom: 50px;
    right: 50px;
}
</style>
