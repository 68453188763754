import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App)

// 根据路由设置标题
router.beforeEach((to, from, next) => {
    /*路由发生改变修改页面的title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next();
})

// ElementPlus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import './assets/iconfont/iconfont.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// SM2
import SM2 from '@/assets/js/utils/sm2'
// Signature
import Signature from '@/assets/js/utils/signature'
// Db
import Db from '@/assets/js/utils/db'
// Date
import Date from '@/assets/js/utils/date'
// Verify
import Verify from '@/assets/js/utils/verify'
// Http
import Http from '@/assets/js/utils/http'



// 自动注册组件
const requireComponents = require.context('./components', true, /\.vue/)
requireComponents.keys().forEach(fileName => {
    const componentInstance = requireComponents(fileName)
    app.component(componentInstance.default.name, componentInstance.default || componentInstance)
})

app.use(store)
app.use(router)
app.use(ElementPlus, {locale: zhCn})
app.config.globalProperties.$sm2 = SM2
app.config.globalProperties.$signature = Signature
app.config.globalProperties.$db = Db
app.config.globalProperties.$date = Date
app.config.globalProperties.$verify = Verify
app.config.globalProperties.$http = Http
app.mount('#app')