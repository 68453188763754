<template>
<div id="ParameterEdit">
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>参数设置</el-breadcrumb-item>
    </el-breadcrumb>
    <el-table v-loading="tableDataLoading" element-loading-text="数据加载中，请稍后......" size="small" :data="tableData" border stripe :height="workerHeight" style="width: 100%; margin-top: 30px;">
        <el-table-column prop="providerName" label="服务商名称" align="center"></el-table-column>
        <el-table-column prop="key" label="配置KEY" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
            <template #default="scope">
                <el-button @click="viewDetail(scope.row)" link type="primary" size="small"><i class="iconfont icon-editor" />编辑</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="centerDialogVisible" title="参数配置" width="80%" destroy-on-close center :close-on-click-modal="false" :draggable="true">
        <el-form :model="systemConfigItem" label-width="100px">
            <el-form-item label="提供者名称">
                <el-input v-model="providerName" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="配置KEY">
                <el-input v-model="systemConfigKey" disabled placeholder=""></el-input>
            </el-form-item>
            <el-form-item v-for="(item, index) in systemConfig" v-bind:key="index" :label="item.key">
                <el-input v-model="item.value" placeholder=""></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取消</el-button>
                <el-button type="primary" :loading="updateDataLocked" @click="buttonSubmit">保存</el-button>
            </span>
        </template>
    </el-dialog>
</div>
</template>

<script>
export default {
    name: 'ParameterEdit',
    props: ['viewId', 'terminalToken'],
    data() {
        return {
            centerDialogVisible: false,
            tableDataLoading: false,
            tableData: [],
            providerName: '',
            systemConfigKey: '',
            systemConfig: [],
            systemConfigItem: {},
            workerHeight: document.documentElement.clientHeight - 350,
            updateDataLocked: false,
        }
    },
    created() {
        if (this.$options.name == this.viewId) {
            this.getLists()
        }
    },
    methods: {
        getLists() {
            this.tableDataLoading = true
            let data = {}
            let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
            this.$http.post('/system/config/lists', data, {
                'terminal-token': this.terminalToken,
                'terminal-signature': terminalSignature
            }, (response) => {
                let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                if (signatureVerify) {
                    let tableData = []
                    response.data.data.forEach((item) => {
                        tableData.push({
                            providerName: item.providerName,
                            key: item.key,
                            value: JSON.parse(JSON.parse(this.$sm2.decrypted(item.value, this.$store.state.clientPrivateKey)))
                        })
                    })
                    this.tableData = tableData
                    this.tableDataLoading = false
                } else {
                    this.$message.error('验证签名失败')
                }
            }, () => {})
        },
        viewDetail(item) {
            let obj = item.value
            this.providerName = item.providerName
            this.systemConfigKey = item.key
            let systemConfig = []
            Object.keys(obj).forEach(key => {
                systemConfig.push({
                    key: key,
                    value: obj[key]
                })
            })
            this.systemConfig = systemConfig
            this.centerDialogVisible = true
        },
        buttonSubmit() {
            this.updateDataLocked = true
            let json = {}
            this.systemConfig.forEach((item) => {
                json[item.key] = item.value
            })
            let data = {
                providerName: this.providerName,
                key: this.systemConfigKey,
                value: this.$sm2.encrypted(JSON.stringify(json), this.$store.state.serverPublicKey)
            }
            let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
            this.$http.post('/system/config/update', data, {
                'terminal-token': this.terminalToken,
                'terminal-signature': terminalSignature
            }, (response) => {
                let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                if (signatureVerify) {
                    this.$message.success('更新成功')
                    this.centerDialogVisible = false
                    this.getLists()
                } else {
                    this.$message.error('验证签名失败')
                }
                this.updateDataLocked = false
            }, () => {
                this.updateDataLocked = false
            })

        }
    }
}
</script>
