<template>
<div id="PasswordChange">
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>密码修改</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :model="form" style="margin: 20px auto 0 auto; padding: 20px; width: 50%">
        <el-form-item label="旧密码" label-width="100px">
            <el-input v-model="form.oldPassword" show-password ref="oldPassword" :inputStyle="inputErrorStyle.oldPassword" maxlength="20" placeholder="请填写旧密码" />
        </el-form-item>
        <el-form-item label="新密码" label-width="100px">
            <el-input v-model="form.newPassword" show-password ref="newPassword" :inputStyle="inputErrorStyle.newPassword" maxlength="20" placeholder="请填写新密码" />
        </el-form-item>
        <el-form-item label="确认密码" label-width="100px">
            <el-input v-model="form.confirmPassword" show-password ref="confirmPassword" :inputStyle="inputErrorStyle.confirmPassword" maxlength="20" placeholder="与新密码一致" />
        </el-form-item>
        <el-form-item class="pbr">
            <el-button type="primary" @click="doSave">保存</el-button>
        </el-form-item>
    </el-form>
</div>
</template>

<script>
export default {
    name: 'PasswordChange',
    props: ['viewId', 'terminalToken'],
    data() {
        return {
            inputErrorStyle: {
                oldPassword: {},
                newPassword: {},
                confirmPassword: {},
            },
            form: {
                oldPassword: '',
                newPassword: '',
                confirmPassword: ''
            },
        }
    },
    methods: {
        doSave() {
            let passwordRule = /^[A-Za-z0-9]+$/
            if (passwordRule.test(this.form.oldPassword)) {
                this.inputErrorStyle.oldPassword = {}
            }
            if (passwordRule.test(this.form.newPassword)) {
                this.inputErrorStyle.newPassword = {}
            }
            if (this.form.confirmPassword == this.form.newPassword) {
                this.inputErrorStyle.confirmPassword = {}
            }
            if (!passwordRule.test(this.form.oldPassword)) {
                this.$nextTick(() => this.$refs.oldPassword.focus());
                this.inputErrorStyle.oldPassword = {
                    borderColor: 'red',
                }
                this.$message.error(
                    '无效的密码'
                )
            } else if (!passwordRule.test(this.form.newPassword)) {
                this.$nextTick(() => this.$refs.newPassword.focus());
                this.inputErrorStyle.newPassword = {
                    borderColor: 'red',
                }
                this.$message.error(
                    '无效的新密码'
                )
            } else if (this.form.newPassword == this.form.oldPassword) {
                this.$nextTick(() => this.$refs.newPassword.focus());
                this.inputErrorStyle.newPassword = {
                    borderColor: 'red',
                }
                this.$message.error(
                    '新密码不能与旧密码相同'
                )
            } else if (this.form.confirmPassword != this.form.newPassword) {
                this.$nextTick(() => this.$refs.confirmPassword.focus());
                this.inputErrorStyle.confirmPassword = {
                    borderColor: 'red',
                }
                this.$message.error(
                    '两次输入的新密码不一致'
                )
            } else {
                let data = {
                    oldPassword: this.$sm2.encrypted(this.form.oldPassword, this.$store.state.serverPublicKey),
                    newPassword: this.$sm2.encrypted(this.form.newPassword, this.$store.state.serverPublicKey),
                }
                let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
                this.$http.post('/passport/password/change', data, {
                    'terminal-token': this.terminalToken,
                    'terminal-signature': terminalSignature
                }, (response) => {
                    let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                    if (signatureVerify) {
                        this.$message.success('密码修改成功')
                    } else {
                        this.$message.error('验证签名失败')
                    }
                })
            }

        }
    }
}
</script>

<style scoped>
.pbr {
    position: fixed;
    bottom: 50px;
    right: 50px;
}
</style>
