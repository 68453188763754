var date = {
    getDateTime(date) {
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        month = (month >= 10) ? month : '0' + month
        day = (day >= 10) ? day : '0' + day
        hours = (hours >= 10) ? hours : '0' + hours
        minutes = (minutes >= 10) ? minutes : '0' + minutes
        seconds = (seconds >= 10) ? seconds : '0' + seconds
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    },
    getBeginTime(date) {
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        month = (month >= 10) ? month : '0' + month
        day = (day >= 10) ? day : '0' + day
        return `${year}-${month}-${day} 00:00:00`
    },
    getEndTime(date) {
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        month = (month >= 10) ? month : '0' + month
        day = (day >= 10) ? day : '0' + day
        return `${year}-${month}-${day} 23:59:59`
    }
}

export default date