<template>
<div id="PersonAdd">
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>人员新增</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :model="form" style="margin: 20px auto 0 auto; padding: 20px; width: 50%">
        <el-form-item label="隶属部门" label-width="100px">
            <el-select v-model="form.orgIndexCode" clearable filterable style="width: 100%" placeholder="">
                <el-option v-for="(item, index) in departmentLists" v-bind:key="index" :label="item.orgName" :value="item.orgIndexCode"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="人员名称" label-width="100px">
            <el-input clearable v-model="form.personName" maxlength="32" placeholder="人员名称，1~32个字符" />
        </el-form-item>
        <el-form-item label="性别" label-width="100px">
            <el-radio-group v-model="form.gender">
                <el-radio-button label="0">未知</el-radio-button>
                <el-radio-button label="1">男</el-radio-button>
                <el-radio-button label="2">女</el-radio-button>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="生日" label-width="100px">
            <el-date-picker clearable style="width: 100%" v-model="form.birthday" type="date" placeholder="请选择出生日期" />
        </el-form-item>
        <el-form-item label="手机号码" label-width="100px">
            <el-input clearable v-model="form.phoneNo" maxlength="20" placeholder="请填写常用手机号码" />
        </el-form-item>
        <el-form-item label="电子信箱" label-width="100px">
            <el-input clearable v-model="form.email" maxlength="20" placeholder="请填写常用电子信箱地址" />
        </el-form-item>
        <el-form-item label="证件类型" label-width="100px">
            <el-radio-group v-model="form.certificateType">
                <el-radio-button label="111">身份证</el-radio-button>
                <el-radio-button label="414">护照</el-radio-button>
                <el-radio-button label="113">户口簿</el-radio-button>
                <el-radio-button label="335">驾驶证</el-radio-button>
                <el-radio-button label="131">工作证</el-radio-button>
                <el-radio-button label="133">学生证</el-radio-button>
                <el-radio-button label="990">其他</el-radio-button>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="证件号码" label-width="100px">
            <el-input clearable v-model="form.certificateNo" maxlength="20" placeholder="请填写证件类型对应的证件号码" />
        </el-form-item>
        <el-form-item label="工号" label-width="100px">
            <el-input clearable v-model="form.jobNo" maxlength="32" placeholder="证件类型为身份证时必填" />
        </el-form-item>
        <el-form-item class="pbr">
            <el-button type="primary" @click="doSave">保存</el-button>
        </el-form-item>
    </el-form>
</div>
</template>

<script>
export default {
    name: 'PersonAdd',
    props: ['viewId', 'unionId', 'terminalToken'],
    data() {
        return {
            workerHeight: document.documentElement.clientHeight - 350,
            departmentLists: [],
            form: {
                orgIndexCode: '',
                personName: '',
                gender: 0,
                birthday: '',
                phoneNo: '',
                email: '',
                certificateType: '111',
                certificateNo: '',
                jobNo: '',
            }
        }
    },
    created() {
        if (this.$options.name == this.viewId) {
            let nowDateTime = new Date()
            let fullYear = nowDateTime.getFullYear() - 18
            let month = nowDateTime.getMonth() - 1
            let date = nowDateTime.getDate()
            this.form.birthday = `${fullYear}-${month}-${date}`
            this.getDepartmentLists()
        }
    },
    methods: {
        getDepartmentLists() {
            let data = {}
            this.tableDataLoading = true
            let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
            this.$http.post('/visitor/organization/lists', data, {
                'terminal-token': this.terminalToken,
                'terminal-signature': terminalSignature
            }, (response) => {
                this.tableDataLoading = false
                let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                if (signatureVerify) {
                    this.departmentLists = response.data.data
                } else {
                    this.$message.error('验证签名失败')
                }
            })
        },
        doSave() {
            if (this.form.orgIndexCode.length == 0) {
                this.$message.error('请选择部门名称！')
            } else if (this.form.personName.length == 0) {
                this.$message.error('请填写人员姓名！')
            } else if (this.form.phoneNo.length == 0) {
                this.$message.error('请填写手机号码！')
            } else if (this.form.certificateNo.length == 0) {
                this.$message.error('请填写证件号码！')
            } else if (this.form.certificateType == '111' && this.form.jobNo.length == 0) {
                this.$message.error('证件类型为身份证时，工号不能为空！')
            } else {
                this.form.gender = parseInt(this.form.gender)
                let data = this.form
                let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
                this.$http.post('/visitor/advance/person/add', data, {
                    'terminal-token': this.terminalToken,
                    'terminal-signature': terminalSignature
                }, (response) => {
                    let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                    if (signatureVerify) {
                        console.log(response)
                    } else {
                        this.$message.error('验证签名失败')
                    }
                })
            }
        }
    }
}
</script>

<style scoped>
.pbr {
    position: fixed;
    bottom: 50px;
    right: 50px;
}
</style>
