<template>
<div id="UserLists">
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :model="topbar" :inline="true" style="margin-top: 20px;">
        <el-form-item>
            <el-input style="width: 452px" v-model="topbar.searchKeyword" placeholder="通行证ID/绑定手机号码/绑定电子邮箱/真实姓名" clearable></el-input>
        </el-form-item>
        <el-form-item>
            <el-select style="width: 15em;" v-model="topbar.passportType" placeholder="用户类型" clearable>
                <el-option v-for="(item, index) in passportTypeLists" v-bind:key="index" :label="item.label" :value="item.value" />
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-button @click="doSearch" type="primary"><i class="iconfont icon-search" />&nbsp;搜索</el-button>
            <el-button @click="doAdd" type="primary"><i class="iconfont icon-search" />&nbsp;新增</el-button>
        </el-form-item>
    </el-form>
    <el-table v-loading="tableDataLoading" element-loading-text="数据加载中，请稍后......" size="small" :data="tableData" border stripe :height="workerHeight" style="width: 100%;">
        <el-table-column prop="passportId" label="通行证ID" align="center"></el-table-column>
        <el-table-column label="用户类型" align="center">
            <template #default="scope">
                <el-tag type="info" effect="dark" v-if="scope.row.passportType == 1" size="small">普通用户</el-tag>
                <el-tag type="success" effect="dark" v-if="scope.row.passportType == 2" size="small">秩序管理</el-tag>
                <el-tag effect="dark" v-if="scope.row.passportType == 3 && scope.row.isRoot == 0" size="small">系统管理</el-tag>
                <el-tag effect="dark" v-if="scope.row.passportType == 4 && scope.row.isRoot == 0" size="small">内部人员</el-tag>
                <el-tag type="warning" effect="dark" v-if="scope.row.passportType == 3 && scope.row.isRoot == 1" size="small"><i class="iconfont icon-vip" style="font-size: 12px;">根用户</i></el-tag>
            </template>
        </el-table-column>
        <el-table-column label="绑定手机号码" align="center">
            <template #default="scope">
                {{scope.row.bindMobile ? scope.row.bindMobile : '未设置'}}
            </template>
        </el-table-column>
        <el-table-column label="绑定电子邮箱" align="center">
            <template #default="scope">
                {{scope.row.bindEmail ? scope.row.bindEmail : '未设置'}}
            </template>
        </el-table-column>
        <el-table-column label="真实姓名" align="center">
            <template #default="scope">
                {{scope.row.realName ? scope.row.realName : '未设置'}}
            </template>
        </el-table-column>
        <el-table-column label="锁定状态" align="center">
            <template #default="scope">
                <el-tag effect="dark" size="small" v-if="scope.row.state == 1" type="success">正常</el-tag>
                <el-tag effect="dark" size="small" v-if="scope.row.state == 2" type="warning">已锁定</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="150">
            <template #default="scope">
                <el-button @click="viewDetail(scope.row.id)" link type="primary" size="small"><i class="iconfont icon-editor" />编辑账号</el-button>
                <span v-if="scope.row.isRoot == 0">
                    <el-button @click="passportLock(scope)" v-if="scope.row.state == 1" link type="warning" size="small"><i class="iconfont icon-block" />锁定账号</el-button>
                    <el-button @click="passportUnlock(scope)" v-if="scope.row.state == 2" link type="success" size="small"><i class="iconfont icon-unlock" />解锁账号</el-button>
                </span>
            </template>
        </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="currentChange" class="pagination" small />
</div>
</template>

<script>
export default {
    name: 'UserLists',
    props: ['viewId', 'terminalToken'],
    data() {
        return {
            workerHeight: document.documentElement.clientHeight - 350,
            passportTypeLists: [{
                    label: '普通用户',
                    value: 1
                },
                {
                    label: '秩序管理',
                    value: 2
                },
                {
                    label: '系统管理',
                    value: 3
                },
            ],
            tableDataLoading: true,
            topbar: {
                searchKeyword: '',
                passportType: '',
                bindMobile: '',
                bindEmail: '',
                lockedState: '',
            },
            total: 0,
            pageSize: 10,
            tableData: [],
        }
    },
    created() {
        if (this.$options.name == this.viewId) {
            this.getDataLists(1)
        }
    },
    methods: {
        getDataLists(pageNumber) {
            let data = {
                pageNumber: pageNumber,
                pageSize: this.pageSize
            }
            Object.keys(this.topbar).forEach(key => {
                if (this.topbar[key]) {
                    data[key] = this.topbar[key]
                }
            })
            this.tableDataLoading = true
            let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
            this.$http.post('/passport/user/lists', data, {
                'terminal-token': this.terminalToken,
                'terminal-signature': terminalSignature
            }, (response) => {
                this.tableDataLoading = false
                let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                if (signatureVerify) {
                    this.total = response.data.data.total
                    this.tableData = response.data.data.list
                } else {
                    this.$message.error('验证签名失败')
                }
            })
        },
        doSearch() {
            this.getDataLists(1)
        },
        doAdd() {
            this.$emit('menuItemIndex', 'UserAdd')
        },
        currentChange(pageNumber) {
            this.getDataLists(pageNumber)
        },
        viewDetail(id) {
            this.$emit('viewUnionId', id)
            this.$emit('menuItemIndex', 'UserEdit')
        },
        passportLock(scope) {
            this.lockedStateSwitch(scope, 2)
        },
        passportUnlock(scope) {
            this.lockedStateSwitch(scope, 1)
        },
        lockedStateSwitch(scope, lockedState) {
            let message = (lockedState == 1) ? '解锁成功' : '锁定成功'
            this.tableData[scope.$index].state = lockedState
            let data = {
                id: scope.row.id,
                lockedState: lockedState,
            }
            let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
            this.$http.post('/passport/user/info/update', data, {
                'terminal-token': this.terminalToken,
                'terminal-signature': terminalSignature
            }, (response) => {
                let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                if (signatureVerify) {
                    this.$message.success(message)
                } else {
                    this.$message.error('验证签名失败')
                }
            }, () => {})
        }
    }
}
</script>

<style lang="less" scoped>
.pagination {
    position: fixed;
    right: 20px;
    bottom: 20px;
}
</style>
