<template>
<div id="PersonLists">
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>人员管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :model="topbar" :inline="true" style="margin-top: 20px;">
        <el-form-item>
            <el-button @click="doAdd" type="primary"><i class="iconfont icon-add" />&nbsp;新增</el-button>
        </el-form-item>
    </el-form>
    <el-table v-loading="tableDataLoading" element-loading-text="数据加载中，请稍后......" size="small" :data="tableData" border stripe :height="workerHeight" style="width: 100%;">
        <el-table-column prop="visitorOrderNo" label="预约订单号" align="center" />
        <el-table-column label="预约开始时间" align="center">
            <template #default="scope">
                <p><i class="iconfont icon-clock" />&nbsp;{{scope.row.beginTime}}</p>
                <p><i class="iconfont icon-ontimeshipment" />&nbsp;{{scope.row.arrivalTime ? scope.row.arrivalTime : '0000-00-00 00:00:00'}}</p>
            </template>
        </el-table-column>
        <el-table-column label="预约结束时间" align="center">
            <template #default="scope">
                <p><i class="iconfont icon-clock" />&nbsp;{{scope.row.endTime}}</p>
                <p><i class="iconfont icon-ontimeshipment" />&nbsp;{{scope.row.departureTime ? scope.row.departureTime : '0000-00-00 00:00:00'}}</p>
            </template>
        </el-table-column>
        <el-table-column prop="companyName" label="来访公司" align="center"></el-table-column>
        <el-table-column prop="departmentName" label="被访部门" align="center"></el-table-column>
        <el-table-column prop="contactPerson" label="接洽人员" align="center"></el-table-column>
        <el-table-column label="预约类型" align="center" width="100">
            <template #default="scope">
                <el-tag v-if="scope.row.visitorType == 1" size="small" effect="dark">{{visitorTypeGroup[scope.row.visitorType - 1]}}</el-tag>
                <el-tag v-if="scope.row.visitorType == 2" type="info" size="small" effect="dark">{{visitorTypeGroup[scope.row.visitorType - 1]}}</el-tag>
                <el-tag v-if="scope.row.visitorType == 3" type="danger" size="small" effect="dark">{{visitorTypeGroup[scope.row.visitorType - 1]}}</el-tag>
                <el-tag v-if="scope.row.visitorType == 4" type="warning" size="small" effect="dark">{{visitorTypeGroup[scope.row.visitorType - 1]}}</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="随访车辆" align="center" width="100">
            <template #default="scope">
                <el-tag v-if="scope.row.useVehicle == 1" effect="dark" type="info" size="small">无</el-tag>
                <el-tag v-if="scope.row.useVehicle == 2" effect="dark" type="success" size="small">有</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="审核状态" align="center" width="100">
            <template #default="scope">
                <el-tag v-if="scope.row.auditState == 1" effect="dark" type="danger" size="small">待审核</el-tag>
                <el-tag v-if="scope.row.auditState == 2" effect="dark" type="success" size="small">已审核</el-tag>
                <el-tag v-if="scope.row.auditState == 3" effect="dark" type="warning" size="small">已驳回</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="200">
            <template #default="scope">
                <el-button @click="viewDetail(scope.row.id)" link type="primary" size="small"><i class="iconfont icon-order" />详情</el-button>
            </template>
        </el-table-column>
    </el-table>
</div>
</template>

<script>
export default {
    name: 'PersonLists',
    props: ['viewId', 'unionId', 'terminalToken'],
    data() {
        return {
            workerHeight: document.documentElement.clientHeight - 350,
            topbar: {},
            tableData: [],
        }
    },
    methods: {
        doAdd() {
            this.$emit('menuItemIndex', 'PersonAdd')
        }
    }
}
</script>
