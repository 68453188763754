import { ElMessage } from 'element-plus'

// Axios
import Axios from 'axios'
if (process.env.NODE_ENV == 'development') {
  Axios.defaults.baseURL = 'http://localhost:9102'
} else {
  Axios.defaults.baseURL = 'https://fk2api.xjai.net'
}

const http = {
  post: (uri, data, headers, response, errorCallBack) => {
    Axios.post(Axios.defaults.baseURL + uri, data, { headers: headers }).then(response).catch((error) => {
      if (error.request.status == 0) {
        ElMessage.error("网络溜号了！！！赶快召唤神龙大侠阿宝~囧")
      } else {
        if (error.request.status == 500) {
          ElMessage.error('服务器溜号了！！！赶快召唤神龙大侠阿宝～囧')
        } else if (error.request.status == 403) {
          ElMessage.error('认证信息无效！！！重新登录试试～')
        } else {
          console.log('err')
          ElMessage.error(error.response.data.message)
        }
      }
      if (errorCallBack) {
        errorCallBack()
      }
    });
  }
}

export default http;