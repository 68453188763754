<template>
<div id="VisitorOrderLists">
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>访客管理</el-breadcrumb-item>
        <el-breadcrumb-item>预约申请单</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :model="topbar" :inline="true" style="margin-top: 20px;">
        <el-form-item>
            <el-input style="width: 452px" v-model="topbar.visitorOrderNo" placeholder="申请单号" clearable></el-input>
        </el-form-item>
        <el-form-item>
            <el-date-picker style="width: 15em;" v-model="topbar.visitorDate" type="date" placeholder="预约日期" clearable />
        </el-form-item>
        <el-form-item>
            <el-select style="width: 15em;" v-model="topbar.receptionistId" placeholder="被访人" clearable>
                <el-option v-for="(item, index) in advancePersonList" v-bind:key="index" :label="item.personName" :value="item.personId" />
            </el-select>
        </el-form-item>
    </el-form>
    <el-form :model="topbar" :inline="true">
        <el-form-item>
            <el-select style="width: 15em;" v-model="topbar.visitorType" placeholder="访客类型" clearable>
                <el-option v-for="(item, index) in visitorTypeLists" v-bind:key="index" :label="item.label" :value="item.value" />
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-select style="width: 15em;" v-model="topbar.auditState" placeholder="审核状态" clearable>
                <el-option v-for="(item, index) in auditStateLists" v-bind:key="index" :label="item.label" :value="item.value" />
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-select style="width: 15em;" v-model="topbar.useVehicle" placeholder="随访车辆" clearable>
                <el-option v-for="(item, index) in useVehicleLists" v-bind:key="index" :label="item.label" :value="item.value" />
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-button @click="doSearch" type="primary"><i class="iconfont icon-search" />&nbsp;搜索</el-button>
        </el-form-item>
        <el-form-item>
            <el-button @click="doRecordExport" type="primary"><i class="iconfont icon-search" />&nbsp;导出访客记录</el-button>
        </el-form-item>
    </el-form>
    <el-table v-loading="tableDataLoading" element-loading-text="数据加载中，请稍后......" size="small" :data="tableData" border stripe :height="workerHeight" style="width: 100%;">
        <el-table-column prop="visitorOrderNo" label="预约订单号" align="center" />
        <el-table-column label="预约开始时间" align="center">
            <template #default="scope">
                <p><i class="iconfont icon-clock" />&nbsp;{{scope.row.beginTime}}</p>
                <p><i class="iconfont icon-ontimeshipment" />&nbsp;{{scope.row.arrivalTime ? scope.row.arrivalTime : '0000-00-00 00:00:00'}}</p>
            </template>
        </el-table-column>
        <el-table-column label="预约结束时间" align="center">
            <template #default="scope">
                <p><i class="iconfont icon-clock" />&nbsp;{{scope.row.endTime}}</p>
                <p><i class="iconfont icon-ontimeshipment" />&nbsp;{{scope.row.departureTime ? scope.row.departureTime : '0000-00-00 00:00:00'}}</p>
            </template>
        </el-table-column>
        <el-table-column prop="workUnit" label="来访公司" align="center"></el-table-column>
        <el-table-column prop="organizationName" label="被访部门" align="center"></el-table-column>
        <el-table-column prop="receptionistName" label="被访人员" align="center"></el-table-column>
        <el-table-column label="预约类型" align="center" width="100">
            <template #default="scope">
                <el-tag v-if="scope.row.visitorType == 1" size="small" effect="dark">{{visitorTypeGroup[scope.row.visitorType - 1]}}</el-tag>
                <el-tag v-if="scope.row.visitorType == 2" type="info" size="small" effect="dark">{{visitorTypeGroup[scope.row.visitorType - 1]}}</el-tag>
                <el-tag v-if="scope.row.visitorType == 3" type="danger" size="small" effect="dark">{{visitorTypeGroup[scope.row.visitorType - 1]}}</el-tag>
                <el-tag v-if="scope.row.visitorType == 4" type="warning" size="small" effect="dark">{{visitorTypeGroup[scope.row.visitorType - 1]}}</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="随访车辆" align="center" width="100">
            <template #default="scope">
                <el-tag v-if="scope.row.useVehicle == 1" effect="dark" type="info" size="small">无</el-tag>
                <el-tag v-if="scope.row.useVehicle == 2" effect="dark" type="success" size="small">有</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="审核状态" align="center" width="100">
            <template #default="scope">
                <el-tag v-if="scope.row.auditState == 1" effect="dark" type="danger" size="small">待审核</el-tag>
                <el-tag v-if="scope.row.auditState == 2" effect="dark" type="success" size="small">已审核</el-tag>
                <el-tag v-if="scope.row.auditState == 3" effect="dark" type="warning" size="small">已驳回</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="200">
            <template #default="scope">
                <el-button @click="viewDetail(scope.row.id)" link type="primary" size="small"><i class="iconfont icon-order" />详情</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="currentChange" class="pagination" small />
</div>
</template>

<script>
export default {
    name: 'VisitorOrderLists',
    props: ['viewId', 'terminalToken'],
    data() {
        return {
            workerHeight: document.documentElement.clientHeight - 350,
            useVehicleGroup: ['无', '有'],
            visitorTypeGroup: ['重要', '一般', '客户', '应聘'],
            auditStateGroup: ['待审核', '已审核', '已驳回'],
            visitorTypeLists: [{
                    label: '重要',
                    value: 1,
                },
                {
                    label: '一般',
                    value: 2,
                },
                {

                    label: '客户',
                    value: 3,
                },
                {
                    label: '应聘',
                    value: 4
                },
            ],
            auditStateLists: [{
                    label: '待审核',
                    value: 1,
                },
                {
                    label: '已审核',
                    value: 2,
                },
                {

                    label: '已驳回',
                    value: 3,
                },
            ],
            useVehicleLists: [{
                label: '无',
                value: 1
            }, {
                label: '有',
                value: 2
            }],
            topbar: {
                visitorOrderNo: '',
                visitorDate: '',
                receptionistId: '',
                visitorType: '',
                auditState: '',
            },
            tableDataLoading: true,
            total: 0,
            pageSize: 10,
            advancePersonList: [],
            tableData: [],
            visitorOrderDetail: {}
        }
    },
    created() {
        if (this.$options.name == this.viewId) {
            this.getAdvancePersonList();
            this.getDataLists(1)
        }
    },
    methods: {
        doRecordExport() {
            let data = {}
            Object.keys(this.topbar).forEach(key => {
                if (this.topbar[key]) {
                    if (key == 'visitorDate') {
                        data.beginTime = this.$date.getBeginTime(this.topbar[key])
                        data.endTime = this.$date.getEndTime(this.topbar[key])
                    } else {
                        data[key] = this.topbar[key]
                    }
                }
            })            
            let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
            this.$http.post('/visitor/record/export', data, {
                'terminal-token': this.terminalToken,
                'terminal-signature': terminalSignature
            }, (response) => {
                let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                if (signatureVerify) {
                    window.open(response.data.data.downloadUrl)
                } else {
                    this.$message.error('验证签名失败')
                }
            })
        },
        getAdvancePersonList() {
            let data = {
                pageNumber: 1,
                pageSize: 1000
            }
            let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
            this.$http.post('/visitor/advance/person/list', data, {
                'terminal-token': this.terminalToken,
                'terminal-signature': terminalSignature
            }, (response) => {
                let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                if (signatureVerify) {
                    console.log(response.data)
                    this.advancePersonList = response.data.data.list
                    console.log(this.advancePersonList)
                } else {
                    this.$message.error('验证签名失败')
                }
            })
        },
        getDataLists(pageNumber) {
            let data = {
                pageNumber: pageNumber,
                pageSize: this.pageSize
            }
            Object.keys(this.topbar).forEach(key => {
                if (this.topbar[key]) {
                    if (key == 'visitorDate') {
                        data.beginTime = this.$date.getBeginTime(this.topbar[key])
                        data.endTime = this.$date.getEndTime(this.topbar[key])
                    } else {
                        data[key] = this.topbar[key]
                    }
                }
            })
            this.tableDataLoading = true
            let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
            this.$http.post('/visitor/order/lists', data, {
                'terminal-token': this.terminalToken,
                'terminal-signature': terminalSignature
            }, (response) => {
                this.tableDataLoading = false
                let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                if (signatureVerify) {
                    this.total = response.data.data.total
                    this.tableData = response.data.data.list
                } else {
                    this.$message.error('验证签名失败')
                }
            })
        },
        doSearch() {
            this.getDataLists(1)
        },
        currentChange(pageNumber) {
            this.getDataLists(pageNumber)
        },
        viewDetail(id) {
            this.$emit('viewUnionId', id)
            this.$emit('menuItemIndex', 'VisitorOrderDetail')
        },
    }
}
</script>

<style lang="less" scoped>
.pagination {
    position: fixed;
    right: 20px;
    bottom: 20px;
}
</style>
