<template>
<div id="VisitorOrderDetail">
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>访客管理</el-breadcrumb-item>
        <el-breadcrumb-item>预约申请单</el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div v-if="dataLoading" style="text-align: right; margin-top: 30px;">
        正在处理数据，请稍后......
    </div>
    <div v-if="!dataLoading" style="text-align: right; margin-top: 30px;">
        <el-button :disabled="auditState == 1 ? false : true" @click="applicationPassed" type="primary">通过申请</el-button>
        <el-button :disabled="auditState == 1 ? false : true" @click="applicationRejected" type="warning">驳回申请</el-button>
    </div>
    <el-row :gutter="5" style="margin-top: 30px;">
        <el-col :span="6">
            <el-card shadow="always">
                <template #header>
                    基本信息
                </template>
                <el-form size="small" :model="visitorOrderInfo">
                    <el-form-item label="申请单号：">{{visitorOrderInfo.visitorOrderNo}}</el-form-item>
                    <el-form-item label="预约类型：">{{visitorOrderInfo.visitorType}}</el-form-item>
                    <el-form-item label="预约日期：">{{visitorOrderInfo.visitorDate}}</el-form-item>
                    <el-form-item label="开始时间：">{{visitorOrderInfo.beginTime}}</el-form-item>
                    <el-form-item label="结束时间：">{{visitorOrderInfo.endTime}}</el-form-item>
                    <el-form-item label="审核状态：">{{visitorOrderInfo.auditState}}</el-form-item>
                </el-form>
            </el-card>
        </el-col>
        <el-col :span="18">
            <el-card shadow="always">
                <template #header>
                    访客信息
                </template>
                <el-table size="small" :data="visitorInfoLists">
                    <el-table-column prop="visitorName" align="center" label="访客姓名"></el-table-column>
                    <el-table-column label="证件类型" align="center">
                        <template #default="scope">
                            <span v-if="scope.row.certificateType == 111">身份证</span>
                            <span v-if="scope.row.certificateType == 414">护照</span>
                            <span v-if="scope.row.certificateType == 113">户口簿</span>
                            <span v-if="scope.row.certificateType == 335">驾驶证</span>
                            <span v-if="scope.row.certificateType == 131">工作证</span>
                            <span v-if="scope.row.certificateType == 133">学生证</span>
                            <span v-if="scope.row.certificateType == 990">其他</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="certificateNo" label="证件号码" align="center"></el-table-column>
                    <el-table-column prop="phoneNo" label="联系电话" align="center"></el-table-column>
                    <el-table-column prop="plateNo" label="车牌号码" align="center"></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template #default="scope">
                            <el-button @click="viewHealthDeclarationDetail(scope.row)" link type="primary" size="small"><i class="iconfont icon-order" />健康申报详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-col>
    </el-row>
    <el-dialog v-model="dialogHealthDeclarationDetail" title="健康申报详情">
        <el-form :model="visitorHealthDeclarationDetail">
            <el-form-item>
                <p style="font-size: 18px; width: 100%">访客姓名</p>
                <p>{{visitorHealthDeclarationDetail.visitorName}}</p>
            </el-form-item>
            <el-form-item>
                <p style="font-size: 18px; width: 100%">个人体温情况</p>
                <p>{{visitorHealthDeclarationDetail.temperature}}</p>
            </el-form-item>
            <el-form-item>
                <p style="font-size: 18px; width: 100%">是否有以下情况？</p>
                <el-checkbox-group v-model="visitorHealthDeclarationDetail.situation">
                    <el-checkbox v-for="(item, index) in situationLists" v-bind:key="index" :label="item.id" disabled>{{item.id}}.{{item.text}}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item>
                <p style="font-size: 18px; width: 100%">最近14天是否有省外/市外旅行史</p>
                <el-radio-group v-model="visitorHealthDeclarationDetail.travelHistory">
                    <el-radio :label="1" disabled>是</el-radio>
                    <el-radio :label="2" disabled>否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item>
                <p style="font-size: 18px; width: 100%">近期行程描述</p>
            </el-form-item>
        </el-form>
    </el-dialog>
</div>
</template>

<script>
export default {
    name: 'VisitorOrderDetail',
    props: ['viewId', 'unionId', 'terminalToken'],
    data() {
        return {
            travelHistory: 1,
            dialogHealthDeclarationDetail: false,
            departmentLists: [],
            advancePersonLists: [],
            auditForm: {
                companyName: '',
                departmentCode: '',
                personId: '',
            },
            dataLoading: true,
            rowId: 0,
            auditTypeGroup: ['待审核', '已审核', '已驳回'],
            visitorTypeGroup: ['重要', '一般', '客户', '应聘'],
            vehicleTypeGroup: ['小型车辆', '中型车辆', '大型车辆'],
            auditState: 1,
            visitorOrderInfo: {
                auditState: '',
                visitorOrderNo: '',
                visitorDate: '',
                beginTime: '',
                endTime: '',
                visitorType: '',
            },
            visitorInfoLists: [],
            visitorVehicleLists: [],
            visitorHealthDeclarationDetail: {},
            situationLists: [{
                    id: 1,
                    text: '14天内曾居住或前往疫情高发地'
                },
                {
                    id: 2,
                    text: '两周内有与确诊患者接触'
                },
                {
                    id: 3,
                    text: '没有出现症状'
                },
                {
                    id: 4,
                    text: '感冒样症状：乏力、精神差、咳嗽、发烧、肌肉痛、头痛'
                },
                {
                    id: 5,
                    text: '喘憋、呼吸急促'
                },
                {
                    id: 6,
                    text: '恶心呕吐、腹泻'
                },
                {
                    id: 7,
                    text: '心慌、胸闷'
                },
                {
                    id: 8,
                    text: '结膜炎（红眼病样表现：眼睛涩、红、分泌物'
                },
            ],
        }
    },
    created() {
        if (this.$options.name == this.viewId) {
            this.rowId = this.$db.getRowId(this.viewId, this.unionId)
            this.getDetailData()
        }
    },
    methods: {
        getDetailData() {
            let data = {
                id: this.rowId
            }
            let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
            this.$http.post('/visitor/order/detail', data, {
                'terminal-token': this.terminalToken,
                'terminal-signature': terminalSignature
            }, (response) => {
                let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                if (signatureVerify) {
                    let visitorOrderInfo = response.data.data.visitorOrderInfo
                    this.auditState = visitorOrderInfo.auditState
                    this.visitorOrderInfo = {
                        auditState: this.auditTypeGroup[visitorOrderInfo.auditState - 1],
                        visitorOrderNo: visitorOrderInfo.visitorOrderNo,
                        visitorDate: visitorOrderInfo.beginTime.split(' ')[0],
                        beginTime: visitorOrderInfo.beginTime.split(' ')[1],
                        endTime: visitorOrderInfo.endTime.split(' ')[1],
                        visitorType: this.visitorTypeGroup[visitorOrderInfo.visitorType - 1],
                        visitorDescribe: (visitorOrderInfo.visitorDescribe) ? visitorOrderInfo.visitorDescribe : ''
                    }
                    this.visitorInfoLists = response.data.data.visitorInfoLists
                    this.dataLoading = false
                } else {
                    this.$message.error('验证签名失败')
                }
            })
        },
        getDepartmentLists() {
            let data = {}
            this.tableDataLoading = true
            let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
            this.$http.post('/visitor/organization/lists', data, {
                'terminal-token': this.terminalToken,
                'terminal-signature': terminalSignature
            }, (response) => {
                this.tableDataLoading = false
                let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                if (signatureVerify) {
                    this.departmentLists = response.data.data
                } else {
                    this.$message.error('验证签名失败')
                }
            })
        },
        departmentSelected(value) {
            console.log(value)
            let data = {
                organizationCode: value
            }
            let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
            this.$http.post('/visitor/advance/person/lists', data, {
                'terminal-token': this.terminalToken,
                'terminal-signature': terminalSignature
            }, (response) => {
                let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                if (signatureVerify) {
                    this.advancePersonLists = response.data.data
                }
            })
        },
        applicationPassed() {
            this.auditOperation(2)
        },
        applicationRejected() {
            this.auditOperation(3)
        },
        auditOperation(auditState) {
            let message = (auditState == 2) ? '通过审核' : '驳回申请'
            let auditStateText = (auditState == 2) ? '已审核' : '已驳回'
            this.$confirm('', {
                title: '申请单审核操作',
                message: `<p>申请单号：${this.visitorOrderInfo.visitorOrderNo}</p><p style="color: red">确认将当前预约申请单${message}？此操作不可撤销！</p>`,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                dangerouslyUseHTMLString: true,
                type: 'warning'
            }).then(() => {
                this.dataLoading = true
                let data = {
                    id: parseInt(this.rowId),
                    auditState: auditState
                }
                let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
                this.$http.post('/visitor/order/audit', data, {
                    'terminal-token': this.terminalToken,
                    'terminal-signature': terminalSignature
                }, (response) => {
                    let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                    if (signatureVerify) {
                        this.dataLoading = false
                        this.auditState = auditState
                        this.visitorOrderInfo.auditState = auditStateText
                        this.$message.success(message)
                    } else {
                        this.$message.error('验证签名失败')
                    }
                }, () => {
                    this.dataLoading = false
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });
            });
        },
        viewHealthDeclarationDetail(row) {
            let data = {
                visitorOrderId: parseInt(this.rowId),
                visitorInfoId: parseInt(row.id)
            }
            let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
            this.$http.post('/common/health/declaration/detail', data, {
                'terminal-token': this.terminalToken,
                'terminal-signature': terminalSignature
            }, (response) => {
                let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                if (signatureVerify) {
                    response.data.data.situation = (JSON.parse(response.data.data.situation))
                    this.visitorHealthDeclarationDetail = response.data.data
                    this.visitorHealthDeclarationDetail.visitorName = row.visitorName
                    this.dialogHealthDeclarationDetail = true
                } else {
                    this.$message.error('验证签名失败')
                }
            })

        }
    }
}
</script>
