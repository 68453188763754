<template>
<div id="app">
    <router-view />
</div>
</template>

<script>
export default {
    name: 'app',
    created() {
        if (localStorage.getItem('terminalToken')) {
            this.$store.commit(
                'updateTerminalToken',
                localStorage.getItem('terminalToken')
            )
        } else {
            if (!this.$verify.token()) {
                this.$router.replace('/')
            }
        }
    },
}
</script>

<style lang="less">
* {
    margin: 0;
    padding: 0;
}

body {
    font-size: 14px;
}
</style>
