<template>
<div id="UserEdit">
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>用户管理</el-breadcrumb-item>
        <el-breadcrumb-item>编辑用户</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :model="form" style="margin: 20px auto 0 auto; padding: 20px; width: 50%">
        <el-form-item label="用户类型" label-width="100px">
            <el-tag effect="dark" type="info" v-if="isRoot == -1">身份识别中...</el-tag>
            <el-tag effect="dark" type="warning" v-if="isRoot == 1"><i class="iconfont icon-vip" style="font-size: 12px;">根用户</i></el-tag>
            <el-radio-group v-if="isRoot == 0" v-model="defaultPassportType" @change="getPassportType">
                <el-radio-button v-for="(item, index) in passportTypeLists" v-bind:key="index" :label="item.label" />
            </el-radio-group>
        </el-form-item>
        <el-form-item label="真实姓名" label-width="100px">
            <el-input v-model="form.realName" maxlength="20" placeholder="请填写真实姓名" />
        </el-form-item>
        <el-form-item label="手机号码" label-width="100px">
            <el-input v-model="form.bindMobile" maxlength="11" placeholder="请填写常用的手机号码" />
        </el-form-item>
        <el-form-item label="邮箱地址" label-width="100px">
            <el-input v-model="form.bindEmail" maxlength="50" placeholder="请填写常用的邮箱地址" />
        </el-form-item>
        <el-form-item label="登录密码" label-width="100px">
            <el-input v-model="form.password" ref="password" show-password maxlength="20" placeholder="请填写登录密码" />
        </el-form-item>
        <el-form-item label="确认密码" label-width="100px">
            <el-input v-model="form.confirmPassword" ref="confirmPassword" show-password maxlength="20" placeholder="请与登录密码保持一致" />
        </el-form-item>
        <el-form-item class="pbr">
            <el-button :loading="submitLoading" type="primary" @click="doSave">{{submitLoading ? '正在更新账户，请稍后......' : '保存'}}</el-button>
        </el-form-item>
    </el-form>
</div>
</template>

<script>
export default {
    name: 'UserEdit',
    props: ['viewId', 'unionId', 'terminalToken'],
    data() {
        return {
            passportTypeLists: [{
                    label: '普通用户',
                    value: 1
                },
                {
                    label: '秩序管理',
                    value: 2
                },
                {
                    label: '系统管理',
                    value: 3
                },
                {
                    label: '内部管理',
                    value: 4
                },
            ],
            isRoot: -1,
            submitLoading: false,
            defaultPassportType: '',
            form: {
                id: 0,
                passportType: '',
                realName: '',
                bindMobile: '',
                bindEmail: '',
                password: '',
                confirmPassword: '',
            },
        }
    },
    created() {
        if (this.$options.name == this.viewId) {
            this.form.id = this.$db.getRowId(this.viewId, this.unionId)
            let data = {
                id: this.form.id
            }
            let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
            this.$http.post('/passport/user/info', data, {
                'terminal-token': this.terminalToken,
                'terminal-signature': terminalSignature
            }, (response) => {
                let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                if (signatureVerify) {
                    this.passportTypeLists.forEach((item) => {
                        if (item.value == response.data.data.passportType) {
                            this.defaultPassportType = item.label
                            this.form.passportType = item.value
                        }
                    })
                    this.isRoot = response.data.data.isRoot
                    this.form.realName = response.data.data.realName
                    this.form.bindMobile = response.data.data.bindMobile
                    this.form.bindEmail = response.data.data.bindEmail
                    this.$nextTick(() => {
                        this.$refs.password.ref.placeholder = response.data.data.password;
                        this.$refs.confirmPassword.ref.placeholder = response.data.data.password;
                    });
                } else {
                    this.$message.error('验证签名失败')
                }
            }, () => {})
        }
    },
    methods: {
        getPassportType(value) {
            this.passportTypeLists.forEach((item) => {
                if (item.label == value) {
                    this.form.passportType = item.value
                }
            })
        },
        doSave() {
            if (this.form.passportType.length == 0) {
                this.$message.error('请选择用户类型')
            } else if (this.form.bindMobile.length == 0) {
                this.$message.error('请填写绑定手机号码')
            } else {
                this.submitLoading = true
                let data = {
                    id: this.form.id,
                    signupType: this.form.passportType,
                    mobile: this.$sm2.encrypted(this.form.bindMobile, this.$store.state.serverPublicKey),
                }
                if (this.form.realName && this.form.realName.length > 0) {
                    data.realName = this.$sm2.encrypted(this.form.realName, this.$store.state.serverPublicKey)
                }
                if (this.form.bindEmail && this.form.bindEmail.length > 0) {
                    data.bindEmail = this.$sm2.encrypted(this.form.bindEmail, this.$store.state.serverPublicKey)
                }
                if (this.form.password && this.form.password.length > 0) {
                    data.password = this.$sm2.encrypted(this.form.password, this.$store.state.serverPublicKey)
                }
                let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
                this.$http.post('/passport/user/info/update', data, {
                    'terminal-token': this.terminalToken,
                    'terminal-signature': terminalSignature
                }, (response) => {
                    let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                    if (signatureVerify) {
                        this.$message.success('更新成功')
                    } else {
                        this.$message.error('验证签名失败')
                    }
                    this.submitLoading = false
                }, () => {
                    this.submitLoading = false
                })
            }
        }
    }
}
</script>

<style scoped>
.pbr {
    position: fixed;
    bottom: 50px;
    right: 50px;
}
</style>
